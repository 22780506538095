import { Image } from '@bw/elements';
import Link from 'next/link';
import { GET_FOOTER_ITEMS } from '../../queries';
import SvgConverter from '../../scripts/SvgConverter';
import { ApiContext } from '../../types/apiContext';
import styles from './footer.module.scss';

interface FooterProps {
	isFranchiseFooter: boolean;
	socialIconsCollection: {
		items: {
			name: string;
			url: string;
			icon: {
				height: number;
				title: string;
				url: string;
				width: number;
			};
		}[];
	};
	footerLogo: {
		image: {
			url: string;
			width: number;
			height: number;
		};
		title: string;
	};
	copyrightText: string;
	addressHeading: string;
	address: string;
	addressCityStateZip: string;
	footerListCollection: {
		items: {
			heading: string;
			listItemsCollection: {
				items: {	
					text: string;
					url: string;
				}[];
			};
		}[];
	};
	legalLinksCollection: {
		items: {
			text: string;
			url: string;		
		}[];
	};
}

export const Footer = async (props: FooterProps) => {
	const getA11yIconText = (iconName: string): string => {
		const normalizedName = iconName.toLocaleLowerCase();
		if (normalizedName.includes('facebook')) return 'Facebook';
		if (normalizedName.includes('instagram')) return 'Instagram';
		if (normalizedName.includes('youtube')) return 'Youtube';
		if (normalizedName.includes('linkedin')) return 'LinkedIn';
		return iconName;
	};

	return (
		<footer className={styles.footerContainer}>
			<figure className={styles.footerLogo} role="none">
				<Image
					src={props.footerLogo.image.url}
					alt={props.footerLogo.title}
					width={props.footerLogo.image.width}
					height={props.footerLogo.image.height}
				/>
			</figure>

			<div className={styles.footerTextSection}>
				<div className={styles.footerSections}>
					{props.footerListCollection.items.map((listSection, index) => (
						<div
							className={`${styles['footerSection']} ${
								props.isFranchiseFooter
									? styles['footerSection--franchiseSection']
									: ''
							}`}
							key={index}
						>
							<span className={styles.listHeader}>{listSection.heading}</span>
							<ul>
								{listSection.listItemsCollection.items.map(
									(listItem, subIndex) => (
										<li key={subIndex}>
											<Link href={listItem.url ? `/${listItem.url}` : '#'}>
												{listItem.text}
											</Link>
										</li>
									)
								)}
							</ul>
						</div>
					))}

					<div className={styles.AddressIconContainer}>
						<div className={styles.addressSection}>
							<span className={styles.addressHeading}>
								{props.addressHeading}
							</span>
							<p>
								{props.address}
								<br />
								{props.addressCityStateZip}
							</p>
						</div>

						<div className={styles.socialIcons}>
							{props.socialIconsCollection.items.map((icon) => (
								<Link
									key={icon.name}
									href={icon.url}
									target="_blank"
									title={icon.name}
									aria-label={`${icon.name} (opens in a new tab)`}
								>
									<SvgConverter />
									<Image
										className="svg"
										src={icon.icon.url}
										width={icon.icon.width}
										height={icon.icon.height}
										alt={icon.icon.title}
									/>
									<span className={styles.visuallyHidden}>
										{getA11yIconText(icon.name)}
									</span>
								</Link>
							))}
						</div>
					</div>
				</div>

				<div className={styles.footerBottom}>
					<div className={styles.linksCopyrightWrapper}>
						<p className={styles.footerCopyright}>{props.copyrightText}</p>

						<ul className={styles.footerLinks}>
							{props.legalLinksCollection.items.map((item, index) => (
								<li key={index}>
									<Link href={item.url}>{item.text}</Link>
								</li>
							))}
						</ul>
					</div>

					<p className={styles.footerCopyright + ' ' + styles.captchaNotice}>
						This site is protected by reCAPTCHA and the Google{' '}
						<Link href="https://policies.google.com/privacy" target="_blank">
							Privacy Policy
						</Link>{' '}
						and{' '}
						<Link href="https://policies.google.com/terms" target="_blank">
							Terms of Service
						</Link>{' '}
						apply.
					</p>
				</div>
			</div>
		</footer>
	);
};

export const resolveProps = async (props: { name: string }, apiContext: ApiContext) => {

	const { data } = await apiContext.contentful.query<{
		footerCollection: { items: FooterProps[] }
	}>({
		query: GET_FOOTER_ITEMS,
		variables: {
			component: props.name as string,
		},
	});

	const componentData = data.footerCollection.items[0];
	return { ...props, ...componentData };	
}

export default Footer;
